<template>
  <router-view
    @changed="changed"
    @submit="submit"
    @prev="prev"
    @next="next"
    @closeGuidance="closeGuidance"
    :prevRouteName="prevRouteName"
    :nextRouteName="nextRouteName"
    :openGuidance="openGuidance"
    :form="form"
    :errors="errors"
    :valid="valid"
    :sending="sending"
    :errorType="errorType"
    :submitting="submitting" />
</template>

<script>
import FormContainerBase from '../../components/FormContainerBase.vue'

export default {
  extends: FormContainerBase,
  name: 'IshikawaFormContainer',
  data () {
    return {
      service: 'ishikawa'
    }
  }
}
</script>
