<template>
  <IshikawaFormContainer :token="token" />
</template>

<script>
import IshikawaFormContainer from './components/IshikawaFormContainer.vue'

export default {
  name: 'App',
  props: ['token'],
  components: {
    IshikawaFormContainer
  }
}
</script>
